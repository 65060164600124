.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--tertiary-color);
    color: var(--white);
  }
}

// drawer
.ant-drawer {
  margin-top: var(--header-bar-size);
  &__bigger {
    margin-top: var(--small-header-bar-size);
  }
}

// date range
.ant-picker-panels {
  tbody {
    tr {
      td {
        text-align: center;
      }
    }
  }
}

.ant-picker,
.ant-picker-range,
.ant-picker-focused {
  border-color: var(--tertiary-color) !important;
  box-shadow: none !important;
}

// date picker
.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-content {
      tbody {
        tr {
          td {
            text-align: center;
          }
        }
      }
    }
  }
}

// submenu items
.ant-menu-submenu {
  .ant-menu-item-selected {
    &:hover {
      a {
        color: var(--white) !important;
      }
    }
  }
}

// buttons
.ant-btn {
  box-shadow: unset;
}

.ant-btn-primary[disabled],
.ant-btn-default[disabled] {
  background: #f5f5f5;
  border-color: var(--tertiary-color);
  box-shadow: none;
  color: rgba(0, 0, 0, 0.25);
  text-shadow: none;
}

.ant-btn-ghost {
  border-color: #d9d9d9;
  color: rgba(40, 44, 54, 0.88);
  &:hover {
    color: var(--tertiary-color);
    border-color: var(--primary-color);
  }
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0px;
}

.ant-btn > .ant-btn-loading-icon {
  margin-right: 8px;
}

// input focus
.ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: var(--tertiary-color) !important;
  box-shadow: none !important;
}

textarea {
  border-color: var(--tertiary-color) !important;
  box-shadow: none !important;
}

// notifications
.ant-notification {
  z-index: 1052;
  .ant-notification-notice-wrapper {
    .ant-notification-notice {
      &-info {
        svg {
          path {
            fill: var(--primary-color);
          }
        }
      }
      &-success {
        svg {
          path {
            fill: var(--tertiary-color);
          }
        }
      }
      &-error {
        svg {
          path {
            fill: var(--red);
          }
        }
      }
    }
  }
}

.ant-modal {
  .ant-modal-content {
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        .anticon {
          color: var(--primary-color)!important;
        }
      }
      .ant-modal-confirm-btns {
        .ant-btn {
          background: var(--primary-color);
          &:hover {
            background-color: var(--tertiary-color);
          }
        }
      }
    }
  }
}

.ant-modal-confirm-btns {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
      color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
}

.ant-modal-confirm-info .anticon-info-circle,
.anticon-exclamation-circle {
  color: var(--tertiary-color) !important;
}

// notifications
.ant-notification-notice
  .ant-notification-notice-content
  .ant-notification-notice-btn
  .ant-btn-primary {
  background: var(--primary-color);
  &:hover {
    background: var(--tertiary-color);
  }
}

// date selector
.ant-picker-cell-in-view {
  &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before {
    background: var(--tertiary-color)!important;
  }
  &.ant-picker-cell-range-end.ant-picker-cell-range-hover {
    &:before {
      background: var(--tertiary-color) !important;
    }
  }
  &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start {
    &:before {
      background: var(--tertiary-color)!important;
    }
    .ant-picker-cell-inner {
      &:after {
        background: var(--tertiary-color)!important;
      }
    }
  }
  &.ant-picker-cell-in-range.ant-picker-cell-range-hover {
    &:before {
      background: var(--tertiary-color)!important;
    }
    .ant-picker-cell-inner {
      &:after {
        background: var(--tertiary-color)!important;
      }
    }
  }
  &.ant-picker-cell-range-start.ant-picker-cell-range-hover-start {
    &:before {
      background: var(--tertiary-color)!important;
    }
    .ant-picker-cell-inner {
      &:after {
        background: var(--tertiary-color)!important;
      }
    }
  }
  &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
    &:before {
      background: var(--tertiary-color)!important;
    }
    .ant-picker-cell-inner {
      &:after {
        background: var(--tertiary-color)!important;
      }
    }
  }
}
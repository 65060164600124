.extraText {
  color: #999;
  font-size: 12px;
}

.alignRight {
  text-align: right;
}

.m-0 {
  margin: 0;
}

.mt-12 {
  margin-top: 12px;
}

.sesame-modal {
  display: flex;
  flex-direction: column;
  height: 550px;

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex: 1;
    overflow-y: auto;
    padding-right: 16px;
  }
}

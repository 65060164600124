.virtualScrollbar {
  position: fixed;
  overflow-y: scroll;
  height: 18px;
  bottom: 40px;
  z-index: 999999999;
  width: 100%;
}
.table-empty {
  color: var(--primary-color);
  svg {
    font-size: 2.2em;
    margin-top: 20px;
  }
  button {
    margin: 10px auto;
  }
  font-size: 2em;
  position: relative;
  text-align: center;
  padding-top: 1em;
}
// https://autodesk.github.io/react-base-table/
.BaseTable {
  &__table {
    .BaseTable__header {
      border-bottom: 1px solid var(--grey);
      border-top: 1px solid var(--grey);
      background: transparent;
      &-cell {
        border-left: 1px solid #f0f0f0;
        background: #fff;
        &:first-of-type {
          border-left: unset;
        }
        &-text {
          color: var(--primary-color);
        }
        &:hover {
          .BaseTable__sort-indicator {
            color: var(--primary-color);
          }
          .BaseTable__column-resizer {
            background: var(--tertiary-color);
          }
        }
        // similar sorting with antd table
        &:hover {
          .BaseTable__header-cell-text {
            &::before {
              content: unset !important;
            }
            &::after {
              content: unset !important;
            }
          }
        }
        &--sorting {
          .BaseTable__header-cell-text {
            &::before {
              content: unset !important;
            }
            &::after {
              content: unset !important;
            }
          }
        }
        &--sortable {
          .BaseTable__header-cell-text {
            max-height: 20px;
            // default grey icons
            position: relative;
            padding-right: 14px;
            &::before {
              // up arrow
              content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="lightgray" aria-hidden="true" data-icon="caret-up" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M858.9 689 530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>');
              width: 0.8em;
              position: absolute;
              right: 0px;
              top: -2px;
            }
            &::after {
              // down arrow
              content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="lightgray" aria-hidden="true" data-icon="caret-down" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>');
              width: 0.8em;
              position: absolute;
              right: 0;
              top: 5px;
            }
          }
          .BaseTable__sort-indicator {
            position: relative;
            visibility: hidden;
            &::before {
              // up arrow
              content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="teal" aria-hidden="true" data-icon="caret-up" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M858.9 689 530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>');
              width: 0.75em;
              visibility: visible;
              position: absolute;
              right: 16px;
              top: -3px;
            }
            &::after {
              // down arrow
              content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="lightgray" aria-hidden="true" data-icon="caret-down" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>');
              width: 0.75em;
              visibility: visible;
              position: absolute;
              right: 16px;
              top: 4.5px;
            }
            &--descending {
              position: relative;
              visibility: hidden;
              &::before {
                // up arrow
                content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="lightgray" aria-hidden="true" data-icon="caret-up" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M858.9 689 530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>');
                width: 0.75em;
                visibility: visible;
                position: absolute;
                right: 14.5px;
                top: -3px;
              }
              &::after {
                // down arrow
                content: url('data:image/svg+xml;utf8,<svg version="1.1" id="" xmlns="http://www.w3.org/2000/svg" fill="teal" aria-hidden="true" data-icon="caret-down" viewBox="0 0 1024 1024" xmlns:xlink="http://www.w3.org/1999/xlink"  style="" xml:space="preserve"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>');
                width: 0.75em;
                visibility: visible;
                position: absolute;
                right: 14.5px;
                top: 4.5px;
              }
            }
          }
        }
      }
    }

    .BaseTable__body {
      .BaseTable__row {
        transition: 0.2s ease-in;
        &-cell {
          border-left: 1px solid #f0f0f0;
          // Knocks out :hover
          &:first-of-type {
            border-left: unset;
          }
        }
        &--hovered {
          background: #cef5ce;
        }
        &--selected {
          background: #cef5ce;
          &.BaseTable__row--hovered{
            background: #bce0bc;
          }
        }
      }
    }
    &-main {
      table-layout: fixed;
      .BaseTable__header {
        position: sticky !important;
        top: 40px;
        z-index: 3;
      }
      .BaseTable__row {
        &.metaRow {
          &.warning,
          &.critical {
            &:before {
              height: 100%;
              display: block;
              content: " ";
              position: absolute;
              left: 0;
              top: -1px;
              bottom: -1px;
            }
          }
          &.warning {
            &:before {
              border-left: rgba(255, 189, 100, 1) 6px solid;
            }
          }
          &.critical {
            &:before {
              border-left: rgba(255, 66, 82, 1) 6px solid;
            }
          }
        }
      }
    }
    &-frozen-left {
      .BaseTable__header {
        position: sticky !important;
        top: 0;
        z-index: 4;
      }
      .BaseTable__row {
        &.metaRow {
          &.warning,
          &.critical {
            &:before {
              height: 100%;
              display: block;
              content: " ";
              position: absolute;
              left: 0;
              top: -1px;
              bottom: -1px;
            }
          }
          &.warning {
            &:before {
              border-left: rgba(255, 189, 100, 1) 6px solid;
            }
          }
          &.critical {
            &:before {
              border-left: rgba(255, 66, 82, 1) 6px solid;
            }
          }
        }
      }
    }
    &-frozen-right {
      .BaseTable__header {
        position: sticky !important;
        // top: 40px;
        top: 0;
        z-index: 4;
        div[role="rowgroup"] {
          position: relative;
          &:before {
            content: "";
            width: 1px;
            height: 100%;
            background: #f0f0f0;
            position: absolute;
            left: 0px;
            top: 0;
            z-index: 3;
          }
        }
      }
    }
    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      background-color: var(--white);
      margin: 0;
      font: inherit;
      color: var(--dark-green);
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid var(--dark-green);
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
      &:hover {
        cursor: pointer;
      }
    }

    input[type="checkbox"]::before {
      content: "";
      width: 0.65em;
      height: 0.65em;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      transform: scale(0);
      transform-origin: bottom left;
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--tertiary-color);
      background-color: CanvasText;
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
  }
}

.with-sidebar {
  width: calc(100vw - 200px) !important;
}

.flex-wrap-row .BaseTable__row-cell {
  display: inline-block;
  vertical-align: middle;
  word-wrap: break-word; 
  white-space: normal;
  padding: 8px;
  min-height: 40px;
}